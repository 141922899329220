import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Mfa1 } from '../../../components/images/support/users/Mfa1.generated';
import { Mfa2 } from '../../../components/images/support/users/Mfa2.generated';
import { Mfa3 } from '../../../components/images/support/users/Mfa3.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reset-user-multi-factor-authentication-mfa-"
    }}>{`Reset User Multi Factor Authentication (MFA) 🔐`}</h1>
    <p>{`Canyou uses Multi Factor Authentication (MFA) to keep the platform secure. `}</p>
    <p>{`Users can choose one of the following two options for MFA.`}</p>
    <ol>
      <li parentName="ol">{`SMS verification`}</li>
      <li parentName="ol">{`Authenticator app verification such as Google Authenticator`}</li>
    </ol>
    <p>{`This section shows how to reset MFA for a user:`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Users menu and click on the user that needs MFA to be reset.`}</li>
    </ol>

    <Mfa1 mdxType="Mfa1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on the user details menu (the 3 dots) and then click `}<strong parentName="li">{`Reset MFA`}</strong></li>
    </ol>

    <Mfa2 mdxType="Mfa2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Follow the prompts then click `}<strong parentName="li">{`Reset MFA`}</strong></li>
    </ol>

    <Mfa3 mdxType="Mfa3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Great work 🙌 the users MFA has been reset. The user will receive an email asking them to reset their MFA.
They will be able to choose between SMS or using an authenticator app`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      